@use "../../styles/config/_config.scss" as *;

.top {
	display: flex;
	background-color: white;

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;

		margin-top: 200px;
		margin-bottom: 80px;
	}
}

.contact {
	margin-top: 80px;
	margin-bottom: 80px;

	&__content {
		display: block;
		margin-bottom: 40px;
	}
}

.wrapper {
	@include max-width;
}

@media screen and (min-width: $breakpoint-md) {
	.contact {
		&__content {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 80px;
			margin-left: 100px;
			margin-right: 100px;
		}
	}
}
