@use "../../../styles/config/config" as *;

$button-background-color: $color-dark !default;
$button-text-color: $color-dark !default;
$button-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !default;
$button-hover-text-color: $color-dark !default;

.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: $button-background-color;
	color: $button-text-color;
	padding: 8px 15px 8px 20px;
	border-radius: 50px;
	font-size: 14px;
	box-shadow: $button-box-shadow;
	transition: 300ms;
	border: none;
	cursor: pointer;

	&.slim {
		padding: 4px 15px 4px 20px;
	}

	&:disabled {
		background-color: $color-disabled;
	}

	&:hover {
		text-decoration: none;
		color: $button-hover-text-color;

		.content {
			transform: translateX(5px);
		}
	}

	&:focus {
		outline: none;
	}
}

.content {
	display: flex;
	align-items: center;

	transition: 300ms;
}
