@use "../../../../../styles/config/config" as *;

.table_row {
	display: table-row;
}

.table_cell {
	border: 1px solid $color-dark;
	display: table-cell;
	padding: 2px 20px;

	p {
		margin: 0;
	}

	.comment_textarea {
		display: block;
		height: 40px;
	}

	.category_select {
		margin: 5px 5px 5px 0;
		display: inline-block;
	}
}

.italic {
	font-style: italic;
}
