@use "../../../styles/config/config" as *;

.accordion {
	display: flex;
	flex-direction: column;

	max-width: 400px;
    margin: 20px 0;
	cursor: pointer;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		color: $color-white;
		font-size: $font-size-lg;
		user-select: none;

		background-color: $color-dark;
		padding: 15px 20px;
		border-radius: 15px;

		.icon {
			position: relative;
			left: 10px;
			vertical-align: middle;
			color: $color-blue;
			transform: rotate(0deg);
			transition: transform 0.3s;
			font-size: 40px;
		}
	}

	.content {
		display: none;

		background-color: #00000005;
		padding: 20px;
	}

	.prices {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;

		background: $color-white;
		padding: 20px;
		border-radius: 10px;

		strong {
			color: #0096B8;
		}
	}

	.priceIcon {
		background: $color-gradient-blue;
		padding: 10px;
		border-radius: 100%;

		margin-right: 10px;
		color: $color-white;
	}

	&[data-opened="true"] {
		.icon {
			transform: rotate(180deg);
			transition: transform 0.3s;
		}

		.content {
			display: block;
		}

		.header {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}
