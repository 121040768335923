@use "../../../styles/config/_config.scss" as *;

.content {
	display: block;
}

.input{
	margin-bottom: 40px;
}

@media screen and (min-width: $breakpoint-md) {
	.content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 80px;
		margin-left: 100px;
		margin-right: 100px;
	}

	.content_end {
		grid-column-end: 3;
	}
}
