@use "../../../styles/config/config" as *;

.main{
	@include max-width;
	background-color: $color-light;
	margin-top: 88px;
    padding-top: 50px;
	padding-bottom: 50px;
	max-width: 1300px;
}

.h1{
	margin: 0 0 30px 0;
}

.create_product_button{
	margin: 0 auto;
    display: block;
    text-align: center;
}

.product_container{
	display: flex;
	justify-content: start;
	flex-wrap: wrap;
}
.product{
	margin: 10px;
	width: 280px;
}