/** _config.scss
 * * Main colors
 */

$color-white: #ffffff;
$color-light: #f4f4f6;
$color-blue: #00B7E0;
$color-dark: #191516;

$color-gradient-blue: linear-gradient(90deg, #0096B8 0%, #0AD2FF 100%);

/** _config.scss
 * * Secondary colors
 */

$color-disabled: #8d8d8d;
$color-error: #ff6161;
$color-success: #88ff70;
$color-success-dark: #58a848;

/** _config.scss
 * * Typography
 */

$font-family-base: "libre-franklin";
$font-family-heading: "work-sans";

$line-height-base: 1.25;

$font-size-xxl: 65px;
$font-size-xl: 35px;
$font-size-lg: 25px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;

/** _config.scss
 * * Breakpoints
 */

$breakpoint-sm: 400px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-xxl: 1680px;

/** _config.scss
 * * Tools
 */

@mixin max-width {
	width: 100%;
	max-width: 1240px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 25px;
	padding-right: 25px;

	@media screen and (min-width: $breakpoint-lg) {
		padding-left: 50px;
		padding-right: 50px;
	}
}
