@use "../../styles/config/config" as *;

.tox-notifications-container{
	display: none;
}

.main {
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.8);
}

.content {
	background-color: $color-light;
	margin: 7% auto;
	padding: 20px;
	border: 1px solid #888;
	width: 900px;
	max-width: 80%;
	position: relative;
}

.closeButton {
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	font-size: $font-size-xl;
	color: $color-dark;
	user-select: none;
}
