@use "../../../styles/config/config" as *;

.mobileMenu {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: $color-dark;

	transition: 300ms;
	transform: translateX(100%);

	pointer-events: none;
    user-select: none;
    visibility: hidden;
}

.isOpen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

	transform: translateX(0);

	pointer-events: auto;
    user-select: auto;
    visibility: visible;
}

.wrapper {
	display: flex;
    flex-direction: column;
    justify-content: space-between;

	@include max-width;
}

.nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
	margin-top: 60px;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

	.ul{
		width: 100%;
		padding: 0;

		.li{
			width: 100%;
			text-align: center;

			.link {
				margin: 20px;
				padding: 15px 0;
				color: $color-white;
				font-size: $font-size-lg;
				display: block;

				&:first-of-type {
					margin-top: 0;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}



.linkActive {
	color: $color-blue;
}

.footer {
	display: flex;
    justify-content: space-around;
    align-items: center;

	min-height: 25px;
	margin-top: 20px;
    margin-bottom: 20px;
}

.footerInfo {
	display: flex;
    align-items: center;
    font-size: $font-size-sm;
	color: $color-light;
	text-decoration: none;
}

.footerInfoIcon {
	padding-right: 5px;
}