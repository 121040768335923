@use "../../styles/config/config" as *;

.notification_container {
	box-sizing: border-box;
	position: fixed;
	z-index: 999999;
	bottom: 12px;
	right: 12px;

	.notification {
		transition: .3s ease;
		pointer-events: auto;
		overflow: hidden;
		margin: 0 0 15px 0;
		width: 300px;
		border-radius: 3px 3px 3px 3px;
		opacity: .9;
		color: $color-white;
		padding: 15px 15px 15px 15px;
		transition: transform .6s ease-in-out;
		animation: toast-in-right .7s;
		user-select: none;
		background-color: $color-success-dark;

		&:hover{
			cursor: inherit;
		}

		.notification_title {
			font-weight: 700;
			font-size: $font-size-md;
			margin-top: 0px;
			margin-bottom: 6px;
			width: 300px;
		}

		.notification_message {
			margin-top: 0px;
			margin-bottom: 0px;
			font-size: $font-size-sm;
		}
	}
}


@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	}
	to {
	  transform: translateX(0);
	}
}
