@use "../../../styles/config/_config.scss" as *;

$color: $color-dark !default;

.container {
  width: 80px;
  height: 80px;
  margin: 0 auto;

  .lds_dual_ring {
	  width: 100%;
	  height: 100%;
	}
	.lds_dual_ring:after {
		content: " ";
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid $color;
		border-color: $color transparent $color transparent;
		animation: lds_dual_ring 1.2s linear infinite;
	}

	.text{
		color: $color;
		margin: 0;
		padding: 0;
	}
}

@keyframes lds_dual_ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}