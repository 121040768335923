@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "libre-franklin";
    src: url('/assets/fonts/libre-franklin/LibreFranklin-ThinItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
