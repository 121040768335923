@use "../../styles/config/config" as *;

.footer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	padding-top: 40px;
	background-color: $color-dark;
}

.wave {
	align-self: center;
}

.content {
	display: block;
	margin-top: 50px;
	margin-bottom: 50px;
	color: $color-white;

	@include max-width;
}

.column {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;

	line-height: 30px;

	a {
		color: $color-white;
	}

	&.m_end {
		position: relative;
		justify-content: flex-end;
	}
}

.airplane {
	width: 120px;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-name: float;
	opacity: 0.8;
}

@keyframes float {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(0);
	}
}

.heading {
	margin-top: 0;
	color: $color-white;
}

.link {
	display: flex;
	align-items: center;
}

.icon {
	display: flex;
	align-items: center;
	color: $color-blue;
	padding-right: 10px;
}

@media screen and (min-width: $breakpoint-md) {
	.content {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 20px;
	}

	.column {
		margin-bottom: 0;
	}
}
