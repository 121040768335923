@use "../../styles/config/config" as *;

.introduction {
	display: flex;
	min-height: calc(100vh - 88px);
	position: relative;
	background: url("background-no-top-bottom-fade.jpg"), linear-gradient(to bottom, #002d38, #0096B8);
	background-position-y: center;
	background-position-x: 80%;
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: screen;
}

.wave {
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 200;
}

.waveBottom {
    height: 200px;
    width: 100%;
    background-color: $color-white;
}
.contentWrapper {
	display: flex;
	align-items: flex-end;
	margin-bottom: 30px;
	margin-left: 50px;
	@include max-width;
}

.content {
	z-index: 300;
    display: block;
    max-width: 530px;
}

.heading {
	font-size: $font-size-xl;
}

@media screen and (min-width: $breakpoint-sm) {
	.waveBottom {
		height: 150px;
	}
}

@media screen and (min-width: $breakpoint-md) {
	.introduction {
		background-position-x: center;
	}
	.waveBottom {
		height: 125px;
	}

	.contentWrapper {
		margin-bottom: 50px;
		margin-left: 50px;
	}

	.heading {
		font-size: $font-size-xxl;
	}
}

@media screen and (min-width: $breakpoint-xl) {
	.waveBottom {
		height: 75px;
	}
}

@media screen and (min-width: $breakpoint-xxl) {
	.waveBottom {
		height: 50px;
	}
}