@use "../../../../styles/config/config" as *;

.accordion {
	display: flex;
	flex-direction: column;
	.header {
		padding: 15px 0;
		color: $color-white;
		font-size: $font-size-lg;
		cursor: pointer;

		.icon {
			position: relative;
			left: 10px;
			vertical-align: middle;
			color: $color-light;
			transform: rotate(0deg);
			transition: transform 0.3s;
			font-size: 40px;
		}
	}

	.content {
		display: none;
		border-top: 1px solid #ffffff30;
		border-bottom: 1px solid #ffffff30;
		padding: 0;

		.li {
			width: 100%;
			text-align: center;

			.link {
				margin: 20px;
				padding: 15px 0;
				color: $color-white;
				font-size: $font-size-lg;
				display: block;

				&:first-of-type {
					margin-top: 0;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

	&[data-opened="true"] {
		.icon {
			transform: rotate(180deg);
			transition: transform 0.3s;
		}
		.content {
			display: block;
		}
	}
}

.linkActive {
	color: $color-blue !important;
}
