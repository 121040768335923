@use "../../../../../styles/config/config" as *;

.table_row {
	width: 100%;
	display: table-row;
}

.table_cell{
	display: table-cell;
	padding: 5px 20px;
	font-size: $font-size-md;
	font-weight: 600
}
