@use "../../../../../styles/config/config" as *;

.main{

}

.heading{
	font-weight: 600;
}

.value{
	margin-left: 10px;
}
