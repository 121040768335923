@use "../../styles/config/config" as *;

$header-links-text-color: $color-dark !default;

.dropdown {
	list-style: none;
	display: inline-block;
	position: relative;
}

.dropdown:focus-within a {
	outline: none;
}

.list {
	padding: 0;
	min-width: 180px;
	background: $color-white;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	transition: all 0.5s ease;
	margin: 0 10px 0;
	left: 0;
	display: none;
	list-style: none;
}

.icon {
	transition: 300ms;
}

.dropdown:hover {
	.icon {
		transform: rotate(180deg);
	}
}

.dropdown:hover > .list,
.list:hover {
	visibility: visible;
	opacity: 1;
	display: block;
}

.list li {
	clear: both;
	display: block;
	margin: 5px 0;
	padding: 0;

	&:first-child {
		margin-top: 22px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		background-color: $color-light;

		a {
			text-decoration: underline;
		}
	}

	a {
		color: $header-links-text-color;
		display: block;
		font-weight: 700;
		font-size: $font-size-sm;
		padding: 15px 20px;
	}
}
