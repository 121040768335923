@use "../../../styles/config/config" as *;

.container {
	border-left: 5px solid $color-blue;
	margin: 0;
	padding: 0;
}

.quote {
	padding: 5px 15px;
	font-style: italic;
	font-size: 20px;
	margin: 5px;
}
