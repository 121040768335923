@use "../../styles/config/config" as *;

.intro {
	background-color: $color-white;
	padding-top: 150px;
	padding-bottom: 40px;
}

.periode {
	margin-top: 60px;
	margin-bottom: 60px;
}

.wrapper {
	@include max-width;
	max-width: 750px;
}
.h1 {
	margin-top: 0;
	text-align: center;
}

.h4 {
	margin-bottom: 10px;
}

.introduction {
	margin-bottom: 30px;
	line-height: 1.6;
	font-size: 18px;
}

.products {
    display: flex;
    flex-wrap: wrap;
	margin-bottom: 30px;

	.productDiv {
		display: inline-block;
		margin: 5px 10px 5px 0;
	}
}

.calendar {
	margin-bottom: 30px;
}
