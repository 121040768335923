@use "../../../styles/config/config" as *;

.main{
	@include max-width;
	background-color: $color-light;
	margin-top: 88px;
    padding-top: 50px;
	padding-bottom: 50px;
}

.logoutContainer{
	margin-bottom: 50px;
}

.linkContainer{
	margin-bottom: 10px;
}

.h1{
	margin: 0;
}