@use "../../styles/config/config" as *;

$header-background-color: $color-dark !default;
$header-links-text-color: $color-dark !default;
$header-links-border-color: $color-dark !default;

.header {
	background-color: $header-background-color;
	position: fixed;
	padding-top: 20px;
	padding-bottom: 20px;
	max-height: 88px;
	width: 100%;
	top: 0;
	z-index: 900;
	color: $header-links-text-color;

	transition: 300ms;
}

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include max-width;
}

.smaller {
	padding-top: 20px;
	padding-bottom: 20px;
}

.link {
	display: inline-flex;
	justify-content: center;
	align-items: center;
    position: relative;
    cursor: pointer;

	padding: 10px;
	color: $header-links-text-color;
	font-weight: 700;
	font-size: $font-size-md;

	&:after {
		content: "";
		height: 5px;
		width: 0%;
		background: $header-links-border-color;
		position: absolute;
		bottom: 0;
		left: 0;
		margin-left: 10px;

		transition: 300ms;
	}

	&.m__active,
	&:hover,
	.drop:hover > & {
		text-decoration: none;

		&:after {
			width: calc(100% - 20px);
		}
	}
}
