@use "../config/config" as *;

.hide-sm {
	display: none;
}

.show-sm {
	display: block;
}

@media screen and (min-width: $breakpoint-lg) {
	.hide-sm {
		display: block;
	}

	.show-sm {
		display: none;
	}
}
