@use "../../../styles/config/config" as *;

.contentTile {
	margin-bottom: 80px;
}

.heading {
	position: relative;

	&:after {
		content: "";
		height: 5px;
		width: 100px;
		background: $color-gradient-blue;
		position: absolute;
		bottom: -10px;
		left: 0;
	}
}
