@use "../../../styles/config/config" as *;

.error {
	font-size: $font-size-xs;
	color: $color-error;
}

.success {
	font-size: $font-size-xs;
	color: $color-success;
}

.successDark{
	font-size: $font-size-xs;
	color: $color-success-dark;
}
