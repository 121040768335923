@use "../../../styles/config/config" as *;

.top {
	display: flex;
	background-color: $color-white;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

    margin-top: 20px;
	margin-bottom: 80px;

	@include max-width;
}

.heading {
    margin-bottom: 30px;
}

.button {
	background: none;
	border: none;
	outline: none;
	margin-bottom: 20px;
	cursor: pointer;

	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-name: bounce;

	.icon {
		color: $color-blue;
		font-size: 45px;
	}
}

.image {
	max-width: 800px;
    width: 100%;
	border-radius: 10px;
}

.imageSmall {
	max-width: 400px;
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(-10px);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}
