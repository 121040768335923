@use "../../../../../styles/config/config" as *;

.main {
}

.lhs {
	margin-right: 5px;
}
.rhs {
	margin-left: 5px;
}

.heading {
	font-weight: 600;
}

.value {
	margin-left: 10px;
}
