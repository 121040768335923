@use "../../../styles/config/_config.scss" as *;

.field {
}

.input {
	display: block;
	font-weight: 700;
	font-size: $font-size-md;
	border: 0;
	-webkit-appearance: none;
	border-radius: 0;
	padding: 12px;
	width: 500px;
	max-width: 100%;
	box-sizing: border-box;

	&:focus {
		outline: 0;
	}
}

.input:focus {
	border-bottom: 4px solid $color-blue;
}
