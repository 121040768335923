@use "../../../styles/config/config" as *;

.editableContent{
	display: inherit;

	.button{
		position: absolute;
		background-color: $color-dark;
		color: $color-white;
		padding: 3px;
		font-size: $font-size-md;
		cursor: pointer;

		&:nth-child(2){
			margin-left: 25px;
		}
	}
}
