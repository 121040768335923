@use "config/config" as *;

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	font-family: $font-family-base;
	font-size: $font-size-md;
	line-height: 1.25;
	color: $color-dark;
	background-color: $color-light;
}

main {
	// Make room for the header
	margin-top: 88px;
}

h1,
h2,
h3 {
	line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-heading;
	font-weight: 700;
	color: $color-dark;
}

h1 {
	font-size: $font-size-xl;

	@media screen and (min-width: $breakpoint-md) {
		font-size: $font-size-xxl;
	}
}

h2 {
	font-size: $font-size-lg;

	@media screen and (min-width: $breakpoint-md) {
		font-size: $font-size-xl;
	}
}

h3 {
	font-size: $font-size-lg;
}

h4 {
	font-size: $font-size-md;
}

h5 {
	font-size: $font-size-sm;
}

h6 {
	font-size: $font-size-xs;
}

img {
	max-width: 100%;
}

a {
	color: $color-blue;

	&:link,
	&:visited {
		text-decoration: none;
	}

	&:hover,
	&:active {
		text-decoration: underline;
	}
}
