@use "../../../styles/config/_config.scss" as *;

.field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.select {
    width: 150px;
    padding: 5px 35px 5px 5px;
    font-size: $font-size-md;
    border: 2px solid $color-blue;
	border-radius: 7px;
    height: 35px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $color-white;
}

.wrap {
	position: relative;
	max-width: 150px;
}

.wrap::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: $color-dark;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);

	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
  }

.select:focus-visible {
    outline: none;
    border: 2px solid $color-dark;
}