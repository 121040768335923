@use "../../../styles/config/config" as *;

.main{
	.calendar_body{
		background-color: #eaedf2;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		padding: 0 10px 5px 10px;
	}

	.error{
		width: 230px;
	}
}


.today{
}

.selected{
	background-color: $color-blue;
}

.selectedStart{
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.selectedEnd{
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.weekly_row{
	display: flex;
	justify-content: space-around;

	.cell{
		width: 35px;
		height: 35px;
		padding: 5px;
		text-align: center;
		user-select: none;
		cursor: pointer;

		flex: 1;
		margin: 10px 0;

		&.disabled{
			color: #c5c8ca;
			cursor: initial;
		}
	}
	.date_number{
	}
}
