@use "../../styles/config/_config.scss" as *;

.wrapper {
	@include max-width;
	padding-top: 1px;
	padding-bottom: 50px;
}

.form_section{
	margin-top: 50px;
}
