@use "../../styles/config/config" as *;

.button {
	z-index: 950;
}

// TODO: Make custom icons instead
.icon {
	cursor: pointer;
	font-size: 30px;
	user-select: none;
}

.iconOpen {
	color: $color-white;
}
