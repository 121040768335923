@use "../../styles/config/config" as *;

.main {
	position: relative;
	display: inline-block;

	width: 100%;

	.calendar_overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.9);
		border-radius: 15px;
		z-index: 99;
		display: flex;
		justify-content: center;
		align-items: center;

		.calendar_overlay_text {
			font-size: $font-size-lg;
			color: #ffffff70;
			letter-spacing: 1px;
		}
	}
}

.dates {
	display: flex;
	justify-content: center;

	&__from {
		border-bottom: 2px solid $color-blue;
		padding-bottom: 5px;
	}

	&__to {
		border-bottom: 2px solid $color-blue;
		padding-bottom: 5px;
	}
}

.datesHint{
	margin-top: 10px;
	font-style: italic;
	font-size: $font-size-sm;
}
