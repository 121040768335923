@use "../../styles/config/config" as *;

.content {
	width: 100%;
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	padding: 25px;

	@media screen and (min-width: $breakpoint-lg) {
		padding: 50px;
	}
}

.accordions {
	margin-top: 50px;

	@media screen and (min-width: $breakpoint-lg) {
		margin-top: 0;
	}
}

@media screen and (min-width: $breakpoint-md) {
	.grid {
		display: grid;
		grid-template-columns: 1fr 300px;
		grid-column-gap: 50px;

		max-width: 1080px;
	}
}