@use "../../../styles/config/_config.scss" as *;

.button {
	margin-top: 20px;
	margin-bottom: 20px;
}

.inputDiv{
	margin-bottom: 10px;
}

.main{
	max-width: 300px;
}
