@use "../../styles/config/config" as *;

.about {
	background-color: $color-light;
	margin-top: 50px;

	@include max-width;
}

.column {
	display: block;
}

@media screen and (min-width: $breakpoint-md) {
	.column {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 20px;
		column-gap: 50px;
	}
}
