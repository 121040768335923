@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "work-sans";
    src: url('/assets/fonts/work-sans/WorkSans-ThinItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
