@use "../../../styles/config/config" as *;

.main{
	@include max-width;
	background-color: $color-light;
	margin-top: 88px;
    padding-top: 50px;
	padding-bottom: 50px;
	max-width: 1400px;
}

.h1{
	margin: 0;
}

.rentals_table{
	display: table;
	justify-content: start;
	flex-wrap: wrap;
}