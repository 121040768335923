@use "../../../styles/config/_config.scss" as *;

.field {
	height: 100%;
}

.textarea {
	display: block;
	font-family: inherit;
	font-weight: 700;
	font-size: $font-size-md;
	border: 0;
	-webkit-appearance: none;
	border-radius: 0;
	padding: 12px;
	resize: none;
	height: 150px;
	width: 100%;
	max-width: 100%;
}

.textarea:focus {
	outline: 0;
}

.textarea:focus {
	border-bottom: 4px solid $color-blue;
}

@media screen and (min-width: $breakpoint-md) {
	.textarea {
		height: 100%;
	}
}
