@use "../../../styles/config/_config.scss" as *;

.content {
	display: block;
	width: 100%;
	max-width: 300px;
}

.input{
	margin-bottom: 10px;
}
