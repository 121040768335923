@use "../../../styles/config/config" as *;

.main {
	.month_container {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		padding: 10px 5px 5px 5px;
		background-color: white;
		display: block;
		width: 100%;
		position: relative;

		.month_name{
			text-align: center;
		}

		.month_arrow {
			position: absolute;
			top: 3px;
			padding: 5px;
			cursor: pointer;
			user-select: none;

			&.month_arrow_left {
				left: 5px;
			}

			&.month_arrow_right {
				right: 5px;
			}
		}
	}
}
.row {
	padding: 0 5px;
	display: flex;
	justify-content: space-around;

	.cell {
		width: 35px;
		height: 35px;
		padding: 5px;
		text-align: center;
		user-select: none;
	}

	&.weekdays{
		color: #c5c8ca;
		background-color: white;
	}
}
